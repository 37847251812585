<template>
  <div>
    <el-row>
      <el-col style="text-align:left">
        <SearchBar>
          <el-input size="small" v-model="searchParams.keyword" placeholder="サーバーコード / サーバー名" clearable style="width:250px;"></el-input>
          <el-select size="small" v-model="searchParams.status" style="width: 250px;margin-left: 15px;">
            <el-option
              v-for="item in statusList"
              v-bind:key="item.value"
              v-bind:label="item.label"
              v-bind:value="item.value">
            </el-option>
          </el-select>
          <el-button size="small" type="primary" style="margin-left: 15px;" icon="el-icon-search" round @click="handleSearch" >{{$t('search.search')}}</el-button>
          <el-button size="small" type="info" style="margin-left: 5px;" round @click="handleReset">{{$t('search.reset')}}</el-button>
        </SearchBar>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <span class="dash-value dash-num-blue" style="margin-top: 20px">{{total}}</span>&nbsp;
        <span class="dash-title">{{$t('listGroup.group')}}</span>&nbsp;
      </el-col>
      <el-col :span="18">
        <div class="searchBar">
          <slot><el-button type="primary" size="small" round icon="el-icon-plus" @click="addRpa" v-if="accountInfo.role <= 3">{{$t('listGroup.add')}}</el-button></slot>
        </div>
      </el-col>
    </el-row>
    <el-table :data="items" style="width: 100%;padding: 10px 10px 10px 10px;">
      <el-table-column prop="serverCode" label="サーバーコード" min-width="140px"></el-table-column>
      <el-table-column prop="serverName" label="サーバー名" min-width="160px"></el-table-column>
      <el-table-column prop="cmmt" label="コンメト" min-width="250px"></el-table-column>
      <el-table-column prop="status" label="状態" min-width="120px">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0" style="color:red;">無効</span>
          <span v-if="scope.row.status === 1" style="color:blue;">有効</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('listGroup.process')" min-width="160px">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="handleUpdateStatus(scope.row.id, 0)" plain round v-if="accountInfo.role <= 3 && scope.row.status === 1">無効</el-button>
          <el-button size="mini" type="warning" @click="handleUpdateStatus(scope.row.id, 1)" plain round v-if="accountInfo.role <= 3 && scope.row.status === 0">有効</el-button>
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row.id)" plain round v-if="accountInfo.role <= 3">{{$t('listUser.edit')}}</el-button>
          <el-button size="mini" type="danger" @click="confirmDelete(scope.row.id)" plain round v-if="accountInfo.role <= 3">{{$t('listGroup.remove')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { listRpa,updateRpa,delRpa } from "@/api/config";
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";

const defaultSearchParams = {
  keyword: "",
  status: -1,
  pageIndex: 1,
  pageSize: 10
};
export default {
  name: "ListRpa",
  data: () => {
    return {
      statusList: [
        {value: -1, label: "すべて"},
        {value: 0, label: "無効"},
        {value: 1, label: "有効"}
      ],
      searchParams: { ...defaultSearchParams },
      totalCount: 0,
      items: []
    };
  },
  mounted() {
    this.refreshData();
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    refreshData() {
      listRpa({
        ...this.searchParams
      }).then(response => {
        this.total = response.data.totalCount;
        this.items = response.data.items;
      });
    },
    handleSizeChange(val){
      this.searchParams = { ...this.searchParams, pageSize: val };
      this.refreshData();
    },
    handleCurrentChange(val) {
      this.searchParams = { ...this.searchParams, pageIndex: val };
      this.refreshData();
    },
    handleSearch() {
        this.searchParams = {...this.searchParams, pageIndex: 1};
        this.refreshData();
    },
    handleReset() {
        this.searchParams = {...defaultSearchParams};
        this.refreshData();
    },
    addRpa(){
      this.$router.push(ROUTER_PATH.ADD_RPA);
    },
    handleUpdate(uid){
      this.$router.push({path:ROUTER_PATH.UPDATE_RPA, query:{id:uid}});
    },
    handleUpdateStatus(id, status) {
      var msg = "該当Rpaサーバーを無効してよろしいでしょうか？"
      if (status == 1) {
        msg = "該当Rpaサーバーを有効してよろしいでしょうか？"
      }
      this.$confirm(msg, this.$t('common.popup'), {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          updateRpa({
            id: id,
            status: status
          }).then(response => {
            this.$message({
              type: 'success',
              message: response.message
            });
            this.refreshData();
          });
        }).catch(() => {
        });
    },
    confirmDelete(id) {
        this.$confirm("該当Rpaサーバーを削除してよろしいでしょうか？", this.$t('common.popup'), {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          delRpa({
            id: id
          }).then(response => {
            this.$message({
              type: 'success',
              message: response.message
            });
            this.refreshData();
          });
        }).catch(() => {
        });
      }
  }
};
</script>
<style>
  .searchBar {
    margin: 0px 0px 10px 0px;
    /*padding: 10px 10px ;*/
    /*background-color: #f7f7f7;*/
    text-align: right;
  }
  </style>
